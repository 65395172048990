<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <AsyncSelect
                            placeholder="Select Product"
                            v-model="selectedProduct"
                            :api-service="fetchProductList"
                            :format-label="option => option.text"
                            :additional-option="additionalOptions"
                            label="text"
                            :additional-query="{ dropdown: 1, with_rate: 1 }"
                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number"
                                class="form-control text-right"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Quantity</label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                :readonly="disableQtyField"
                            />
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>
                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-percent">Discount Percent</label>
                        <input
                          v-model.trim="data.discount_percent"
                          type="number" class="form-control"
                          placeholder="Discount(%)"
                          :min="0"
                          id="discount-percent"
                        >
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount_amount">Discount Amount</label>
                        <vField
                          v-model="data.discount_amount"
                          name="discount-amount"
                          type="number"
                          class="form-control text-right"
                          min="0"
                          id="discount_amount"
                        />
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount_amount">After Discount Amount</label>
                        <vField
                          v-model="amountAfterDiscount"
                          name="amount"
                          type="number"
                          class="form-control text-right"
                          disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT Rate</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select text-right"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">Vat amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <slot v-if="isItem">
                  <div class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="batch_number">Batch Number</label>
                      <vField
                        v-model="data.batch_number"
                        name="batch_number"
                        type="text"
                        id="batch_number"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="mfn_date">Manufacturing Date</label>
                      <vField
                        v-model="data.mfn_date"
                        name="mfn_date"
                        id="mfn_date"
                        type="month"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="exp_date">Expiry Date</label>
                      <vField
                        v-model="data.exp_date"
                        name="exp_date"
                        id="exp_date"
                        type="month"
                        class="form-control"
                      />
                    </div>
                  </div>

                </slot>

                <div class="col-md-6 col-12">
                      <label class="tds-vds-label mb-1">
                          <span class="me-2">TDS VDS Compliance</span>
                      </label> 

                      <button @click="$emit('onClickToApplyTdsVds')" v-if="!data.is_vat_added" type="button" class="btn btn-primary btn-sm btn-block">
                          <i class="fas fa-plus"></i>
                      </button>

                      <button v-if="data.is_vat_added" @click="$emit('onClickToApplyTdsVds')" type="button" class="btn btn-outline-primary btn-sm btn-block">
                          <i class="fas fa-pen"></i>
                      </button>

                      <div class="card" v-if="data.is_vat_added">
                          <div class="card-body">
                              <ul class="no-dots">
                                  <li>
                                      <span>VDS / Mushak 6.3 Amount = </span>
                                      <span>{{ commaFormat(data.vds_amount) }}</span>
                                  </li>
                                  <li>
                                      <span>TDS Amount = </span>
                                      <span>{{ commaFormat(data.tds_amount) }}</span>
                                  </li>
                                  <li>
                                      <span>Net Payable Amount = </span>
                                      <span>{{ commaFormat(data.net_payable_amount) }}</span>
                                  </li>
                              </ul>
                          </div>
                      </div>
                </div>

                <div class="col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                         <vField
                            rows="2"
                            as="textarea"
                            v-model="data.description"
                            class="form-control"
                            name="description"
                        />
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "@vue/runtime-core"
import {onMounted, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";

const {fetchProductList} = handlePurchaseAndSales()
const route = useRoute();
const store = useStore();
const vatAmount = ref(0);
const selectedProduct = ref(null);
const { commaFormat } = figureFormatter();

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  accountHeads: Array,
  onClickToApplyTdsVds: Function
})

const emit = defineEmits(['onClickToApplyTdsVds']);

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
    if(!pr.isItem) {
        return pr.data.amount
    }
})

const vatPercent = computed(() => pr.data.vat);

const vatAmountInput = computed(() => pr.data.vat_amount)

const amountAfterDiscount = computed(() => {
    const discountAmount = pr.data.discount_amount === '' ? 0 : parseFloat(pr.data.discount_amount)
    if(!discountAmount || discountAmount === 0) {
        return amount.value;
    }
    return amount.value - discountAmount
})

const additionalOptions = computed(() => {
  if (route.params.purchaseId && pr.data.product_id && pr.data.product) {
    return [
      {
        id: pr.data.product_id,
        text: pr.data.product.name,
      }
    ]
  }
});

const discountPercent = computed(() => pr.data.discount_percent);
const disableQtyField = computed(() => pr.data.hasOwnProperty('can_edit_qty') && !pr.data.can_edit_qty );

watch(vatPercent, () => {
  if(pr.data.vat === 0 && pr.data.vat_amount === 0) {
    vatAmount.value = 0
    return;
  }

  if(pr.data.vat && pr.isItem) {
    vatAmount.value = (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2)
    return;
  }

  vatAmount.value = (pr.data.vat/100 * pr.data.amount).toFixed(2)
})

watch(selectedProduct, (newValue) => {
    if(! newValue) {
        pr.data.product_id = null;
        pr.data.account_head_name = '';
        pr.data.vds_id = null;
        pr.data.vds_rate = 0;
        pr.data.tds_id = null;
        pr.data.tds_rate = 0;
        pr.data.product = null;
        return;
    }

    pr.data.product_id = newValue.id ?? null;
    pr.data.account_head_name = newValue.inventory_account_head_name;
    pr.data.vds_id = newValue.vds_id;
    pr.data.vds_rate = newValue.vds_rate;
    pr.data.tds_id = newValue.vds_id;
    pr.data.tds_rate = newValue.tds_rate;
    pr.data.product = newValue;
    pr.data.product.name = newValue.text;
})

watch(vatAmountInput, () => {
    vatAmount.value = pr.data.vat_amount ?? 0
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue) || !route.params.purchaseId){
    pr.data.vat_amount  = newValue;
  }
})

watch(discountPercent, (value) => {
    if(!value) return;

    let discount = 0;
    value = parseFloat(value);
    if(amount.value > 0) {
        discount = amount.value * (value/100);
    }
    pr.data.discount_percent = value;
    pr.data.discount_amount = discount.toFixed(2);
})

const totalAmount = computed(() => {
    if(pr.data.vat === 0 && vatAmount.value === 0) {
        return parseInt(amountAfterDiscount.value)
    }
    if(pr.isItem) {
        return parseInt(amountAfterDiscount.value) + parseInt(vatAmount.value)
    }
    return parseInt(amountAfterDiscount.value) + parseInt(vatAmount.value)
})

watch(() => pr.data.product, (product) => {
    
    if(pr.data.is_set_product) return;

    setInitialProduct(product);

    pr.data.is_set_product = true;
})

const setInitialProduct = (product) => {
    if(!pr.isItem || !product) return;
    
    const item = product;
    item.name = product.name;
    item.text = product.name;
    item.inventory_account_head_name = pr.data.account_head_name;
    item.vds_id = pr.data.general_vds_id || pr.data.vds_id;
    item.vds_rate = pr.data.vds_rate;
    item.tds_id = pr.data.general_tds_id || pr.data.tds_id;
    item.tds_rate = pr.data.tds_rate;

    selectedProduct.value = item;
}

onMounted(() => {
    if (pr.data && pr.data.product) {
        setInitialProduct(pr.data.product);
    }
})

</script>


<style scoped>
.journal-line{
    border: 1px solid #f0f2f5;
}
.journal-line:hover{
    background: #f0f2f5;
}
.card-body {
  padding: .5rem;
}
.no-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.tds-vds-label {
    font-size: 16px;
    font-weight: 500;
}
</style>

